






















































/**
 * @name 产品中心详情
 * */
import {
  defineComponent,
  ref,
  onBeforeMount,
  toRefs,
  onMounted,
  nextTick,
} from "@vue/composition-api";
import bgImg from "@/components/bgImg/index.vue";
import Menu from "@/components/menu/index.vue";
import InfoWarp from "@/components/infowarp/index.vue";
import offcanvas from "@/components/offcanvas/index.vue";
import Dswiper from "./components/swiper.vue";
// import Dsw from "./components/sw.vue";
import Info from "./components/info.vue";
import SelfSwiper from "@/components/swiper/index.vue";
import HtmlInfo from "@/components/content/html.vue";
// import { useList, useDom } from "@/use/getData";
import { useList, getListToTypeFn, useDom } from "@/use";
export default defineComponent({
  components: {
    bgImg,
    Menu,
    InfoWarp,
    offcanvas,
    Dswiper,
    // Dsw,
    SelfSwiper,
    Info,
    HtmlInfo,
  },
  setup() {
    const src = ref("products/products.jpeg");
    // const refresh = ref(false);
    const title = "产品中心";
    const arr = ref([
      {
        label: "OEM",
        id: 1,
        children: [{ label: "非标定制行业整机", id: 2 }],
      },
      {
        label: "工业平板电脑",
        id: 3,
        children: [
          { label: "7寸工业平板电脑", id: 4 },
          { label: "8寸工业平板电脑", id: 5 },
          { label: "9寸工业平板电脑", id: 6 },
        ],
      },
    ]);
    // const nodeClick = (val: any) => {
    //   activeId.value = val.id;
    // };
    const {
      query,
      Mdata,
      contentData,
      nodeClick: nCfn,
      meunActive,
    } = getListToTypeFn("product", "id");
    const { offcanvas, showOffcanvasMenu, skipPage } = useDom();
    // 点击目录
    const nodeClick = (val: any) => {
      nCfn(val);
      if (query.type) {
        skipPage("/products", {
          type: "list",
          menuactive: val.id,
        });
      }
    };

    // const swiperData = [
    //   {
    //     imgUrl: "products/1.jpeg",
    //   },
    //   {
    //     imgUrl: "products/2.jpeg",
    //   },
    //   {
    //     imgUrl: "products/1.jpeg",
    //   },
    // ];
    // const handleDetails = (row: any) => {
    //   skipPage("/products", {
    //     type: "details",
    //     id: row.id,
    //   });
    // };
    return {
      src,
      title,
      // nodeClick,
      query,
      arr,
      // activeId,
      // ...toRefs(state),
      nodeClick,
      fields: ["name", "value"],
      ...toRefs(contentData),
      ...toRefs(meunActive),
      Mdata,
      offcanvas,
      showOffcanvasMenu,
      // swiperData,
    };
  },
});
