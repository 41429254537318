
















































import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {
    Swiper,
    SwiperSlide,
  },
})
export default class extends Vue {
  @Prop(Array) swiperData!: () => {};
  @Prop(String) height!: "440px";
  created() {
    this.setFn();
  }
  mounted() {
    const swiperTop = (<any>this.$refs.swiperTop).$swiper;
    const swiperThumbs = (<any>this.$refs.swiperThumbs).$swiper;

    this.$nextTick(() => {
      swiperTop.controller.control = swiperThumbs;
      swiperThumbs.controller.control = swiperTop;
      swiperTop.update();
      swiperThumbs.update();
    });
  }
  swiperOptionTop = {};
  swiperOptionThumbs = {};
  public setFn() {
    this.swiperOptionTop = {
      // loop: true,
      // loopedSlides: 5, // looped slides should be the same
      // spaceBetween: 10,
      // navigation: {
      //   nextEl: ".swiper-button-next",
      //   prevEl: ".swiper-button-prev",
      // },
      loop: true,
      loopedSlides: 3, // looped slides should be the same
      spaceBetween: 10,
      watchSlidesVisibility: true, //防止不可点击
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      zoom: {
        containerClass: "swiper-slide",
      }, //开启缩放功能
    };
    this.swiperOptionThumbs = {
      loop: true,
      loopedSlides: 3,
      spaceBetween: 10,
      centeredSlides: true,
      slidesPerView: "auto",
      touchRatio: 0.2,
      slideToClickedSlide: true,
    };
  }
}
